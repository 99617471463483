<template>
  <div class="top-wrapper">
    <header class="top-header">
      <div class="top-header__inner">
        <ClientOnly>
          <h1 class="top-header__title">
            <nuxt-link to="/">
              <img
                src="/img/top/logo.svg"
                width="207"
                height="19"
                alt="WARC AGENT"
                class="header-img"
              />
            </nuxt-link>
          </h1>
          <div class="top-header__links">
            <nuxt-link
              v-if="isPc"
              :to="fullUrl('/articles')"
              class="link-item normal"
            >
              転職者向けマガジン
            </nuxt-link>
            <nuxt-link v-if="isPc" to="/contact" class="link-item bold">
              採用をお考えの企業様はこちら
            </nuxt-link>
            <div class="top-header__button">
              <ButtonsTopHeaderButton
                text="転職サポートを登録する"
                type="link"
                :path="fullUrl('/registration')"
              />
            </div>
          </div>
        </ClientOnly>
      </div>
    </header>
    <slot />
    <footer class="top-footer">
      <div class="top-footer__innter">
        <div class="top-footer__logo">
          <nuxt-link to="/">
            <img
              src="/img/top/logo-white.svg"
              width="223"
              height="19"
              alt="WARC AGENT"
            />
          </nuxt-link>
          <span class="top-footer__copy">&copy; WARC.inc</span>
        </div>
        <div class="top-footer__links">
          <span class="links-title">わたしたちについて</span>
          <ul class="links-list">
            <li class="list__item">
              <nuxt-link to="https://corp.warc.jp/" target="_blank"
                >運営会社</nuxt-link
              >
            </li>
            <li class="list__item">
              <nuxt-link
                href="https://corp.warc.jp/privacy-policy"
                target="_blank"
              >
                プライバシーポリシー
              </nuxt-link>
            </li>
          </ul>
        </div>
        <div class="top-footer__links">
          <span class="links-title">関連サービス</span>
          <ul class="links-list">
            <li class="list__item">
              <nuxt-link :to="fullUrl('/articles')">
                WARC エージェント マガジン
              </nuxt-link>
            </li>
            <li class="list__item">
              <nuxt-link to="https://candidate.synca.net/" target="_blank">
                SYNCA
              </nuxt-link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup lang="ts">
const isPc = useState(() => false);
const checkWindowWidth = () => {
  isPc.value = screen.width > 767;
};

onMounted(() => {
  checkWindowWidth();
  window.addEventListener('resize', checkWindowWidth);
});
</script>
